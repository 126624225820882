<template>
  <div class="login-form">
    <form class="form" @submit.prevent="submit">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Bem vindo a Plataforma de Planos de Investimento
        </h3>
        <span class="text-muted font-weight-bold font-size-h4"
          >Ainda não tem cadastro?
          <b-link
            class="text-primary font-weight-bolder"
            :to="{ name: 'register' }"
          >
            Cadastre-se
          </b-link>
        </span>
      </div>
      <div class="form-group">
        <label class="font-size-h6 font-weight-bolder text-dark">Email</label>
        <div id="example-input-group-1" label="" label-for="example-input-1">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="email"
            name="email"
            autocomplete="off"
            v-model="form.email"
            required
          />
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between mt-n5">
          <label class="font-size-h6 font-weight-bolder text-dark pt-5">
            Senha
          </label>
          <b-link
            class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
            :to="{ name: 'recover' }"
          >
            Esqueceu a senha?
          </b-link>
        </div>
        <div id="example-input-group-2" label="" label-for="example-input-2">
          <input
            class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
            type="password"
            name="password"
            v-model="form.password"
            required
            autocomplete="off"
          />
        </div>
      </div>
      <div class="pb-lg-0 pb-5">
        <button
          type="submit"
          class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
          :disabled="loading"
          :class="{
            spinner: loading,
            'spinner-light': loading,
            'spinner-right': loading
          }"
        >
          Entrar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { LOGIN, PURGE_AUTH } from "@/core/store/auth.module";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      form: {
        email: null,
        password: null
      }
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        // clear existing errors
        this.$store.commit(PURGE_AUTH);

        // set spinner to submit button
        this.loading = true;

        // send login request
        this.$store
          .dispatch(LOGIN, this.form)
          .then(() => {
            const redirect = this.$route.query.redirect;
            if (
              redirect &&
              this.$router.resolve(redirect).resolved.name !== "404"
            ) {
              this.$router.push(redirect);
            } else {
              this.$router.push({ name: "inicio" });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
